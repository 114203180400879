/* width */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #8484856b;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8484856b;
}

div::-webkit-scrollbar:horizontal {
  width: 8px;
  height: 8px;
}
/* Hide Number Input Roller */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

/* @font-face {
  font-family: "crayond_bold";
  src: url("./font/Poppins-Bold.ttf");
}

@font-face {
  font-family: "crayond_medium";
  src: url("./font/Poppins-Medium.ttf");
}

@font-face {
  font-family: "crayond_regular";
  src: url("./font/Poppins-Regular.ttf");
} */

@font-face {
  font-family: "crayond_bold";
  src: url("./font/NunitoSans-Bold.ttf");
}
@font-face {
  font-family: "crayond_semibold";
  src: url("./font/NunitoSans-SemiBold.ttf");
}
@font-face {
  font-family: "crayond_extrabold";
  src: url("./font/NunitoSans-ExtraBold.ttf");
}
@font-face {
  font-family: "crayond_regular";
  src: url("./font/NunitoSans-Regular.ttf");
}
@font-face {
  font-family: "crayond_light";
  src: url("./font/NunitoSans-Light.ttf");
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
html {
  height: 100%;
}

#root {
  height: 100%;
}

.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
